import { mapActions } from 'vuex';

//---helpers
import { BLOG_CONTENT_TYPE, BLOG_VIDEOS_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import IconClock from '@/components/icons/IconClock.vue';
import ContentBlock from '@/components/custom/ContentBlock/index.vue';

export default {
  name: 'Video',
  mixins: [dynamicHead],
  components: { ContentBlock, IconClock, BreadcrumbsSimple },
  props: {},
  data() {
    return {
      BLOG_CONTENT_TYPE,
      videoType: BLOG_VIDEOS_TYPE.MASTER_CLASS,
      videoData: null
    };
  },
  created() {
    let { name, path } = this.$route;
    if (name.indexOf(BLOG_VIDEOS_TYPE.VIDEOS) > -1) {
      this.videoType = BLOG_VIDEOS_TYPE.VIDEOS;
    }
    console.log('created', { name, path });
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    breadcrumbs() {
      return this.videoType === BLOG_VIDEOS_TYPE.VIDEOS
        ? [{ title: this.$t('page.video.title'), route: { name: 'videos' } }]
        : [{ title: this.$t('page.masterClass.title'), route: { name: 'master-classes' } }];
    }
  },
  methods: {
    ...mapActions({
      getBlogVideo: 'blog/GET_BLOG_VIDEO',
      getBlogMasterClass: 'blog/GET_BLOG_MASTER_CLASS'
    }),
    async _loadContent() {
      globalLoader(true);
      let { slug } = this.$route.params;
      let result = null;
      if (this.videoType === BLOG_VIDEOS_TYPE.VIDEOS) {
        result = await this.getBlogVideo(slug);
      } else if (this.videoType === BLOG_VIDEOS_TYPE.MASTER_CLASS) {
        result = await this.getBlogMasterClass(slug);
      }

      this.videoData = result.data;
      this._setMetaParams();
      this.setMeta();
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, title, metaDescription, image } = this.videoData;
      if (title || metaTitle)
        this.metaParams.title = `${metaTitle ? metaTitle : title}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
    }
  }
};
